<template>
  <product title="Amazfit 智能手表"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="799"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://j.youzan.com/92YjKh"
    is-bus-city1
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'verge',
  components: {
    Product
  },
  data () {
    return {
      slogan: 'NFC 百城交通卡 | 智能家居控制 | 内置小爱同学<br/> AI 自动甄别心律不⻬(含房颤)',
      colors: [
        '天际灰',
        '夜光白',
        '暮色蓝'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/verge/swiper/5.png',
          CDN_URL + '/images/product/verge/swiper/6.png',
          CDN_URL + '/images/product/verge/swiper/7.png',
          CDN_URL + '/images/product/verge/swiper/8.png'
        ],
        [
          CDN_URL + '/images/product/verge/swiper/9.png',
          CDN_URL + '/images/product/verge/swiper/10.png',
          CDN_URL + '/images/product/verge/swiper/11.png',
          CDN_URL + '/images/product/verge/swiper/12.png'
        ],
        [
          CDN_URL + '/images/product/verge/swiper/1.png',
          CDN_URL + '/images/product/verge/swiper/2.png',
          CDN_URL + '/images/product/verge/swiper/3.png',
          CDN_URL + '/images/product/verge/swiper/4.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        CDN_URL + '/images/product/verge/detail/01w.jpg',
        CDN_URL + '/images/product/verge/detail/02.jpg',
        CDN_URL + '/images/product/verge/detail/03.jpg',
        CDN_URL + '/images/product/verge/detail/04.jpg',
        CDN_URL + '/images/product/verge/detail/05.jpg',
        CDN_URL + '/images/product/verge/detail/06.jpg',
        CDN_URL + '/images/product/verge/detail/07.jpg',
        CDN_URL + '/images/product/verge/detail/08.jpg',
        CDN_URL + '/images/product/verge/detail/09.jpg',
        CDN_URL + '/images/product/verge/detail/10.jpg',
        CDN_URL + '/images/product/verge/detail/11.jpg',
        CDN_URL + '/images/product/verge/detail/12.jpg',
        CDN_URL + '/images/product/verge/detail/13.jpg',
        CDN_URL + '/images/product/verge/detail/14.jpg',
        CDN_URL + '/images/common/zepp-white.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/verge/detail/15.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
